const LocationReviewsData = [
  {
    "customer": "Daniel R.",
    "review": "Installed a new garage door quickly and professionally. The service was outstanding. Highly recommended!",
    "service": "New Garage Door Installation"
  },
  {
    "customer": "Laura F.",
    "review": "Great experience with my new garage door installation. The team was friendly and efficient.",
    "service": "New Garage Door Installation"
  },
  {
    "customer": "Samuel K.",
    "review": "Excellent service! My new garage door looks fantastic, and the installation was smooth.",
    "service": "New Garage Door Installation"
  },
  {
    "customer": "Sophie M.",
    "review": "The team installed my new garage door opener efficiently. It's working perfectly now. Great job!",
    "service": "Garage Door Opener"
  },
  {
    "customer": "John H.",
    "review": "The garage door opener installation was fast, and the techs were knowledgeable. Highly recommend!",
    "service": "Garage Door Opener"
  },
  {
    "customer": "Megan T.",
    "review": "The team replaced my old opener, and it's working flawlessly. Very satisfied with the service.",
    "service": "Garage Door Opener"
  },
  {
    "customer": "Liam P.",
    "review": "Had my garage door springs replaced. Fast, reliable, and safe service. Thank you, BHO Garage Door!",
    "service": "Garage Door Springs Replacement"
  },
  {
    "customer": "Angela V.",
    "review": "Spring replacement was done quickly and safely. The door is working like new again!",
    "service": "Garage Door Springs Replacement"
  },
  {
    "customer": "Ryan G.",
    "review": "Broken spring was replaced promptly. The team ensured everything was secure. Great work!",
    "service": "Garage Door Springs Replacement"
  },
  {
    "customer": "Olivia T.",
    "review": "BHO Garage Door fixed my garage door quickly. It's running smoothly again. Very satisfied!",
    "service": "Residential Garage Door Repair"
  },
  {
    "customer": "Carlos A.",
    "review": "Impressed by the quick repair. The technicians were polite and got the job done right.",
    "service": "Residential Garage Door Repair"
  },
  {
    "customer": "Grace N.",
    "review": "The door repair was professional, and they cleaned up after. Great service overall!",
    "service": "Residential Garage Door Repair"
  },
  {
    "customer": "Ethan W.",
    "review": "The team repaired the damage on my garage door. Excellent service and results. I’m impressed!",
    "service": "Damaged Garage Door Fix"
  },
  {
    "customer": "Victor J.",
    "review": "They fixed the damage quickly, and the door works better than before. Great experience!",
    "service": "Damaged Garage Door Fix"
  },
  {
    "customer": "Emily R.",
    "review": "Professional repair service for my damaged garage door. Efficient, friendly, and reliable!",
    "service": "Damaged Garage Door Fix"
  },
  {
    "customer": "Ava K.",
    "review": "Emergency garage door repair at 3 AM! Fast, friendly, and got the job done right.",
    "service": "24/7 Emergency Garage Door Repair"
  },
  {
    "customer": "Brian L.",
    "review": "Called them in the middle of the night, and they arrived within an hour. Very grateful!",
    "service": "24/7 Emergency Garage Door Repair"
  },
  {
    "customer": "Diana T.",
    "review": "Fantastic emergency response. They repaired the door quickly and were very professional.",
    "service": "24/7 Emergency Garage Door Repair"
  },
  {
    "customer": "Henry B.",
    "review": "Full commercial garage repair done efficiently. Very professional service, and I highly recommend them.",
    "service": "Full-Service Commercial Garage Repair"
  },
  {
    "customer": "Nina C.",
    "review": "Great job on our commercial garage. They were quick, efficient, and attentive to details.",
    "service": "Full-Service Commercial Garage Repair"
  },
  {
    "customer": "Jordan S.",
    "review": "The technicians were experienced and thorough. The commercial garage repair exceeded my expectations.",
    "service": "Full-Service Commercial Garage Repair"
  },
  {
    "customer": "Mia J.",
    "review": "The new gate installation was seamless. The team was professional, and the gate looks amazing.",
    "service": "New Gate Installation"
  },
  {
    "customer": "Patrick H.",
    "review": "Very satisfied with my new gate. The installation was quick, and the result is fantastic.",
    "service": "New Gate Installation"
  },
  {
    "customer": "Rachel B.",
    "review": "Great experience with the gate installation. The team was knowledgeable, and the design is perfect.",
    "service": "New Gate Installation"
  }
]
  
export default LocationReviewsData;
