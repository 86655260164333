import { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import './HomePageGoogle.scss';
import { useLocation, useNavigate } from "react-router-dom";
import Faq from "react-faq-component";
// images
import LocationReviewsData from './LocationReviewsData.js'
import BookAnAppointment from "./BookAnAppointment/BookAnAppointment.jsx";
import Header from "./Header/Header.jsx";
import Footer from "./Footer/Footer.jsx";
import emailjs from 'emailjs-com';

export default function HomePageGoogle({ showMenu, setShowPopUp, locationsMain, setShowMenu, CitiesData, CitiePhone }) {
    const location = useLocation()
    const navigate = useNavigate()
    const [FormSubmiting, setFormSubmiting] = useState(false);
    const url = new URL(window.location.href);

    // Faqs
    const data = {
        title: "",
        rows: [
            {
                "title": "Why Choose BHO Garage Door?",
                "content": "With years of experience, we provide professional, reliable BHO Garage Door services, ensuring safety and efficiency in every project."
            },
            {
                "title": "What Services Do You Offer?",
                "content": "We offer residential and commercial garage door installations, opener setups, repairs, and maintenance to keep your doors operating smoothly."
            },
            {
                "title": "How Much Is a Garage Door Inspection?",
                "content": "Our garage door inspection is free, helping identify any issues before recommending further action."
            },
            {
                "title": "Can You Repair and Install on the Same Day?",
                "content": "Yes, we offer same-day repairs and installations during your appointment if needed."
            },
            {
                "title": `Do you provide services surrounding ${CitiesData && CitiesData.City ? CitiesData.City : "Your Area"}?`,
                "content": `Yes, we don't just serve ${CitiesData && CitiesData.City ? CitiesData.City : "Your Area"}! We cover a wide area, including a 75-mile radius around ${CitiesData && CitiesData.City ? CitiesData.City : "Your Area"}. Whether you're in nearby towns or surrounding areas, we're here to help.`
            },
            {
                "title": "How Do I Book an Appointment?",
                "content": `Call us at <a href='tel:${CitiePhone}'>${CitiePhone}</a> or fill out the <a href='/local/book${location.search}'>online form</a> to schedule your service.`
            }
        ]
    };
    const styles = {
        bgColor: '#ebf2ff',
        titleTextColor: 'white',
        titleTextSize: '18px',
        rowTitleColor: '#1b294b',
        rowTitleTextSize: '21px',
        rowContentColor: '#242424',
        rowContentTextSize: '16px',
        // rowContentTextWeight: '600',
        rowContentPaddingTop: '10px',
        rowContentPaddingBottom: '10px',
        rowContentPaddingLeft: '5px',
        rowContentPaddingRight: '5px',
        arrowColor: "#1b294b",
        //transitionDuration: "1s",
        // timingFunc: "ease"
    };
    const config = {
        // animate: true,
        // arrowIcon: "V",
        // tabFocus: true
        openOnload: 0,
    };


    return (
        <div className="HomePageGoogle" id="homepage">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>BHO Garage Door | Expert Garage Door Repair, Inspection & Services | {CitiePhone}</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="BHO Garage Door | Expert BHO Garage Door, Inspection & Services | {CitiePhone}" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="BHO Garage Door | Expert BHO Garage Door, Inspection & Services | {CitiePhone}" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.bhogaragedoor.com/" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="BHO Garage Door provides top-quality BHO Garage Door, inspections, and repairs to keep your home safe and efficient. Trust our experienced professionals for all your Garage Door service needs." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="BHO Garage Door provides top-quality BHO Garage Door, inspections, and repairs to keep your home safe and efficient. Trust our experienced professionals for all your Garage Door service needs." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="BHO Garage Door" data-react-helmet="true" />
                <meta name="keywords" content="BHO Garage Door, Garage Door Inspection, Garage Door Inspection, Fireplace Inspection, Fireplace, BHO Garage Door, Garage Door Maintenance, Fireplace Maintenance, Dryer Vent, Garage Door Inspection, Fireplace Inspection, Fireplace, BHO Garage Door, Garage Door Maintenance, Fireplace Maintenance, Dryer Vent, Garage Door services, Garage Door service, Garage Door Inspection, Fireplace Inspection, Fireplace, BHO Garage Door, Garage Door Maintenance, Fireplace Maintenance, Dryer Vent, Garage Door Insulation, Garage Door Installation, Garage Door Liner Repair, Garage Door Pointing, Garage Door Rainp Installation, Garage Door Repair, Garage Door Crown Repair, Garage Door Restoration, Garage Door Flue Installation, Garage Door Flue Repair, ​Garage Door Construction, Garage Door Vent Installation, Garage Door Flashing, Flexible Garage Door Liner Installation, Garage Door Damper Repair, Chimneyp Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rainp Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplacep" data-react-helmet="true" />
            </Helmet>
            {/* Header */}
            <Header showMenu={showMenu} setShowPopUp={setShowPopUp} locationsMain={locationsMain} CitiesData={CitiesData} setShowMenu={setShowMenu} CitiePhone={CitiePhone} />
            {/* <div className="HomePageGoogleDuscount"> */}
            <div className="HomePageGoogleDuscount" >
                <a onClick={() => navigate(`/book${location.search}`)}>BOOK ONLINE</a>
                <h3>OR CALL US AT</h3>
                <a href={`tel:${CitiePhone}`}>{CitiePhone}</a>
            </div>
            {/* MainBanner */}
            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <div className="MainBannerLeft1">
                        <h1>B.H.O Garage Door Repair Company of {CitiesData ? CitiesData.City : "Your Area"}</h1>
                        <h4>Rated #1 Garage Company</h4>
                        <div className="MainBannerLeft1Box">
                            <img src={require(`../Images/bgnobgs.png`)} alt="BHO Garage Door Trust Review" />
                            <img src={require(`../Images/bonded.png`)} />
                            <img src={require(`../Images/100.png`)} />
                        </div>
                        <h2>The Garage Door Experts You've Trusted for Over 20 Years</h2>
                        <h6>Servicing <span>{CitiesData ? CitiesData.Full : "Your Area"}</span> And Surrounding Areas</h6>
                        <div className="MainBannerLeft1Coupon">
                            <h2>New Customers Offer</h2>
                            <h3>10% OFF COUPON</h3>
                            <h4>Valid For All Services</h4>
                        </div>
                        {/* <h2>Trusted Garage Door Repair Company in In {CitiesData ? CitiesData.Full : "Your Area"} For Over 20 Years</h2> */}
                        <h3><img src={require(`../Images/quality.png`)} alt="Locally Owned & Operated BHO Garage Door Repair" />Locally Owned & Operated</h3>
                        <h3><img src={require(`../Images/quality.png`)} alt="Licensed | Bonded | Insured BHO Garage Door Repair" />Licensed | Bonded | Insured</h3>
                        <h3><img src={require(`../Images/quality.png`)} alt="Satisfaction Guaranteed BHO Garage Door Repair" />Satisfaction Guaranteed</h3>
                        <div className="MainBannerLeft1BtnBonded">
                            <div className="MainBannerLeftButtons">
                                <a href={`tel:${CitiePhone}`}>{CitiePhone} <img src={require(`../Images/telephone.png`)}></img></a>
                                <a onClick={() => navigate(`/local/book${location.search}`)}>CLICK TO SCHEDULE ONLINE<img src={require(`../Images/book.png`)} alt="BHO Garage Door Near Me Master" /></a>
                            </div>
                            <img className='MainBannerLeft1BtnBondedImg' src={require(`../Images/bonded.png`)} />
                            <img className='MainBannerLeft1BtnBondedImg' src={require(`../Images/100.png`)} />
                        </div>
                    </div>
                    <div className="MainBannerLeft2">
                        <img src={require(`../Images/bgnobgs.png`)} alt="BHO Garage Door Trust Review" />
                        <span>
                            <img className='MainBannerLeft1BtnBondedImg' src={require(`../Images/bonded.png`)} />
                            <img className='MainBannerLeft1BtnBondedImg' src={require(`../Images/100.png`)} />
                        </span>
                    </div>
                </div>
                <div className="MainBannerRight">

                    <div className="MainBannerRightBubble2"></div>
                    <img src={require(`../Images/13BB7.png`)} alt="BHO Garage Door Master| Garage Door Repair Master| Garage Door Services Master| Garage Door Inspection Master" />
                    <div className="MainBannerRightBubble1"></div>
                </div>
            </div>
            <div className="MainBannerTestimonoals">
                <h6><b>{url.searchParams.get('kd') ? url.searchParams.get('kd').includes('near') ? "" + url.searchParams.get('kd').replace('near me', '•') : "" + url.searchParams.get('kd') + "" : "Garage Door Services"}</b>, Broken Spring, Off Track, Openers, New Door Installation And Any Other Garage Services</h6>

                <h1>5 STAR - Based On 3,618 Reviews</h1>
                <div className="MainBannerLeftImages">
                    <img src={require(`../Images/YELP.png`)} alt="BHO Garage Door Yelp Review" />
                    <img src={require(`../Images/Angie.png`)} alt="BHO Garage Door Angie Review" />
                    <img src={require(`../Images/HomeAdvisor.png`)} alt="BHO Garage Door HomeAdvisor Review" />
                    <img src={require(`../Images/BBB.png`)} alt="BHO Garage Door BBB Review" />
                    <img src={require(`../Images/Google.png`)} alt="BHO Garage Door Google Review" />
                    <img src={require(`../Images/Porch.png`)} alt="BHO Garage Door Porch Review" />
                    <img src={require(`../Images/Trust.png`)} alt="BHO Garage Door Trust Review" />
                </div>
                <h2>Customer Testimonials</h2>
                <span>
                    <img src={require(`../Images/stars.png`)} />
                    <img src={require(`../Images/stars.png`)} />
                    <img src={require(`../Images/stars.png`)} />
                    <img src={require(`../Images/stars.png`)} />
                    <img src={require(`../Images/stars.png`)} />
                </span>
                <h3>I've used BHO Garage Door {CitiesData ? " In " + CitiesData.City + " " : ""} for various repairs and maintenance, and I'm consistently impressed by their exceptional service. With over 20 years of industry experience, they are reliable and detail-focused, ensuring quality results. Their prompt response and commitment to safety and customer satisfaction make them my go-to choice for garage door needs.</h3>
                <a onClick={() => window.location.hash = 'reviews'}>MORE FROM OUR CUSTOMERS &gt;</a>
            </div>
            {/* BookAnAppointmentMainComponentEmergery */}
            <div className="BookAnAppointmentMainComponentEmergery1" >
                <div className="BookAnAppointmentMainComponentEmergeryBox">
                    <img src={require(`../Images/GarageImg33.jpg`)} />
                    <p>Secure Garage, Safe Home. Call BHO Garage Door!</p>
                    <a href={`tel:${CitiePhone}`}> Click To Call: {CitiePhone}</a>
                </div>
            </div>
            {/* MainBanner2Top2 */}
            <div className="MainBanner2Top2" id="ourservices">
                <div className="MainBanner2TopBoxMenuShadow1"></div>
                <div>
                    <div className="MainBanner2TopBoxMenu">
                        <a className="MainBanner2TopBoxBox">
                            <img className='MainBanner2TopBoxImg' src={require(`../Images/12GargeNew.png`)} alt="BHO Garage Door" />
                            <div className="MainBanner2TopBoxBox2">
                                <h3>New Garage Door Installation</h3>
                                <h2>Transform your home with expert garage door installations. Call now for a free estimate!</h2>
                                <div className="MainBanner2TopBoxBox2Btns">
                                    <a href={`tel:${CitiePhone}`}>{CitiePhone} <img src={require(`../Images/telephone.png`)}></img></a>
                                    <a onClick={() => navigate(`/local/book${location.search}`)}>SCHEDULE ONLINE<img src={require(`../Images/book.png`)} alt="BHO Garage Door Near Me Master" /></a>
                                </div>
                            </div>
                        </a>
                        <a className="MainBanner2TopBoxBox">
                            <img className='MainBanner2TopBoxImg' src={require(`../Images/12GargeOpener.png`)} alt="BHO Garage Door" />
                            <div className="MainBanner2TopBoxBox2">
                                <h3>Garage Door Opener</h3>
                                <h2>Smooth, reliable garage door opener installations. Enjoy convenience! Contact us for fast service.</h2>
                                <div className="MainBanner2TopBoxBox2Btns">
                                    <a href={`tel:${CitiePhone}`}>{CitiePhone} <img src={require(`../Images/telephone.png`)}></img></a>
                                    <a onClick={() => navigate(`/local/book${location.search}`)}>SCHEDULE ONLINE<img src={require(`../Images/book.png`)} alt="BHO Garage Door Near Me Master" /></a>
                                </div>
                            </div>
                        </a>
                        <a className="MainBanner2TopBoxBox">
                            <img className='MainBanner2TopBoxImg' src={require(`../Images/12GargeSpring.png`)} alt="BHO Garage Door" />
                            <div className="MainBanner2TopBoxBox2">
                                <h3>Garage Door Springs Replacement</h3>
                                <h2>Broken springs? We replace them quickly for safety and reliability. Call for same-day service!</h2>
                                <div className="MainBanner2TopBoxBox2Btns">
                                    <a href={`tel:${CitiePhone}`}>{CitiePhone} <img src={require(`../Images/telephone.png`)}></img></a>
                                    <a onClick={() => navigate(`/local/book${location.search}`)}>SCHEDULE ONLINE<img src={require(`../Images/book.png`)} alt="BHO Garage Door Near Me Master" /></a>
                                </div>
                            </div>
                        </a>
                        <a className="MainBanner2TopBoxBox">
                            <img className='MainBanner2TopBoxImg' src={require(`../Images/13BB7.png`)} alt="BHO Garage Door" />
                            <div className="MainBanner2TopBoxBox2">
                                <h3>Residential Garage Door Repair</h3>
                                <h2>Get prompt, reliable repairs for your home’s garage door. Call us now for help!</h2>
                                <div className="MainBanner2TopBoxBox2Btns">
                                    <a href={`tel:${CitiePhone}`}>{CitiePhone} <img src={require(`../Images/telephone.png`)}></img></a>
                                    <a onClick={() => navigate(`/local/book${location.search}`)}>SCHEDULE ONLINE<img src={require(`../Images/book.png`)} alt="BHO Garage Door Near Me Master" /></a>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="MainBanner2TopBoxMenu">
                        <a className="MainBanner2TopBoxBox">
                            <img className='MainBanner2TopBoxImg' src={require(`../Images/12GargeParts.png`)} alt="BHO Garage Door" />
                            <div className="MainBanner2TopBoxBox2">
                                <h3>Damaged Garage Door Fix</h3>
                                <h2>Fix your damaged garage door fast. Quality repairs for lasting safety. Call us today!</h2>
                                <div className="MainBanner2TopBoxBox2Btns">
                                    <a href={`tel:${CitiePhone}`}>{CitiePhone} <img src={require(`../Images/telephone.png`)}></img></a>
                                    <a onClick={() => navigate(`/local/book${location.search}`)}>SCHEDULE ONLINE<img src={require(`../Images/book.png`)} alt="BHO Garage Door Near Me Master" /></a>
                                </div>
                            </div>
                        </a>
                        <a className="MainBanner2TopBoxBox">
                            <img className='MainBanner2TopBoxImg' src={require(`../Images/12GargeResidential.png`)} alt="BHO Garage Door" />
                            <div className="MainBanner2TopBoxBox2">
                                <h3>24/7 Emergency Garage Door Repair</h3>
                                <h2>Emergency garage door repairs, 24/7. Fast response, anytime you need!</h2>
                                <div className="MainBanner2TopBoxBox2Btns">
                                    <a href={`tel:${CitiePhone}`}>{CitiePhone} <img src={require(`../Images/telephone.png`)}></img></a>
                                    <a onClick={() => navigate(`/local/book${location.search}`)}>SCHEDULE ONLINE<img src={require(`../Images/book.png`)} alt="BHO Garage Door Near Me Master" /></a>
                                </div>
                            </div>
                        </a>
                        <a className="MainBanner2TopBoxBox">
                            <img className='MainBanner2TopBoxImg' src={require(`../Images/12GargeCom.png`)} alt="BHO Garage Door" />
                            <div className="MainBanner2TopBoxBox2">
                                <h3>Full-Service Commercial Garage Repair</h3>
                                <h2>Keep your business running smoothly with our commercial garage repairs.</h2>
                                <div className="MainBanner2TopBoxBox2Btns">
                                    <a href={`tel:${CitiePhone}`}>{CitiePhone} <img src={require(`../Images/telephone.png`)}></img></a>
                                    <a onClick={() => navigate(`/local/book${location.search}`)}>SCHEDULE ONLINE<img src={require(`../Images/book.png`)} alt="BHO Garage Door Near Me Master" /></a>
                                </div>
                            </div>
                        </a>
                        <a className="MainBanner2TopBoxBox">
                            <img className='MainBanner2TopBoxImg' src={require(`../Images/12GargeGate.png`)} alt="BHO Garage Door" />
                            <div className="MainBanner2TopBoxBox2">
                                <h3>New Gate Repair & Installation</h3>
                                <h2>Upgrade your property with professional gate installations. Call now for a free consultation!</h2>
                                <div className="MainBanner2TopBoxBox2Btns">
                                    <a href={`tel:${CitiePhone}`}>{CitiePhone} <img src={require(`../Images/telephone.png`)}></img></a>
                                    <a onClick={() => navigate(`/local/book${location.search}`)}>SCHEDULE ONLINE<img src={require(`../Images/book.png`)} alt="BHO Garage Door Near Me Master" /></a>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

                <div className="MainBanner2TopBoxMenuShadow2"></div>
            </div>
            {/* HomePageGoogleReviews */}
            <div className="HomePageGoogleReviews" id="reviews">
                <h1>Trusted by Our Community</h1>
                <div className="HomePageGoogleReviewsList">
                    {LocationReviewsData.map((A, index) =>
                        <div key={index} className="HomePageGoogleReviewsBox">
                            <h3>{A.review}</h3>
                            <div className="HomePageGoogleReviewsBoxMore">
                                <img src={require(`../Images/people.png`)} />
                                <span>
                                    <h2>{A.customer}</h2>
                                    <h4>
                                        <img src={require(`../Images/stars.png`)} />
                                        <img src={require(`../Images/stars.png`)} />
                                        <img src={require(`../Images/stars.png`)} />
                                        <img src={require(`../Images/stars.png`)} />
                                        <img src={require(`../Images/stars.png`)} />
                                    </h4>
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* MainBanner2 */}
            <div className="MainBanner2" id="about">
                <div className="MainBanner2Left">
                    <div className="MainBanner2TopTopRight">
                        <h4>MASTER YOUR GARAGE WITH BHO GARAGE DOOR!</h4>
                        <h5>It’s more than a service—it’s a commitment to your comfort and safety.</h5>
                        <div className="MainBanner2TopBox">
                            <h2>TRUSTED EXPERTISE</h2>
                            <h3>Since 2001, BHO Garage Door has set the standard for garage door services in <b>{CitiesData && CitiesData.City ? CitiesData.City.toUpperCase() : "Your Area"}</b> with a reputation built on excellence and years of expertise.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>CLEAR & HONEST PRICING</h2>
                            <h3>No surprises, no gimmicks—just transparent pricing that covers all Garage Door services, even on holidays.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>FAST & RELIABLE SERVICE</h2>
                            <h3>Need urgent help? We offer same-day and emergency Garage Door services, 365 days a year, to keep your home fresh and safe.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>TOP-TIER QUALITY</h2>
                            <h3>From thorough to reliable repairs, our team uses advanced techniques and state-of-the-art tools for a job done right every time.</h3>
                        </div>
                    </div>

                    {/* Title */}
                    <div className="MainBanner2TopTopBtn">
                        <a href={`tel:${CitiePhone}`}>CALL NOW {CitiePhone}</a>
                        <a onClick={() => navigate(`/local/book${location.search}`)}>SCHEDULE ONLINE<img src={require(`../Images/book.png`)} alt="BHO Garage Door Master| Garage Door Repair Master| Garage Door Services Master| Garage Door Inspection Master" /></a>
                    </div>
                </div>
                <div className="MainBanner2RightRgt">
                    <img src={require(`../Images/GarageImg33.jpg`)} />

                </div>
            </div>
            {/* HomePageGoogleBefore */}
            <div className="HomePageGoogleBefore">
                <h1>Quality BHO Garage Door Services</h1>
                <h2>We’re proud to provide community members with the best BHO Garage Door residential and commercia service in the industry. </h2>
                <div className="HomePageGoogleBeforeImages">
                    <img src={require(`../Images/13BB1.jpeg`)} />
                    <img src={require(`../Images/13BB2.png`)} />
                    <img src={require(`../Images/13BB3.png`)} />
                    <img src={require(`../Images/13BB4.png`)} />
                    <img src={require(`../Images/13BB5.png`)} />
                    <img src={require(`../Images/13BB6.png`)} />
                    <img src={require(`../Images/13BB7.png`)} />
                    <img src={require(`../Images/13BB8.png`)} />
                </div>
            </div>
            {/* <div className="HomePageGoogleCoupon" id="off"> */}
            <div className="HomePageGoogleCoupon" id="off">
                <div className="HomePageGoogleCouponTop">
                    <div className="HomePageGoogleCouponText">
                        <h1>Get 10% Off and Complimentary Estimate on All Services!</h1>
                        <h2>At BHO Garage Door, we believe in delivering top-notch service at a great value. That’s why we offer free estimates for all our services. Whether it’s residential Garage Door, commercial, maintenance service, or any repairs you can count on us to provide clear, honest pricing before we start.</h2>
                        <h3>Contact us today to schedule your free estimate and take advantage of this limited-time offer! <a href={`/local/book${location.search}`}>Online appointments available</a></h3>
                    </div>
                    <div className="HomePageGoogleCouponMain" style={{ "display": "none" }}>
                        <div className="coupon">
                            <div class="left">
                                <div><h3>Enjoy Your Gift</h3></div>
                            </div>
                            <div class="center">
                                <div>
                                    <h2>10% OFF</h2>
                                    <h3>Coupon</h3>
                                    <small>ALL SERVICES</small>
                                </div>
                            </div>
                            <div class="right">
                                <div>MASTER</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* BookAnAppointment */}
            <BookAnAppointment locationsMain={locationsMain} CitiesData={CitiesData} CitiePhone={CitiePhone} />
            {/* HomePageGoogleBefore */}
            <div className="HomePageGoogleCompanies">
                <h1>Expert Repairs for All Garage Door and Gate Brands</h1>
                <h2>handle everything from minor fixes to major repairs, ensuring your garage doors and gates operate smoothly and safely. Trust us for reliable service and fast solutions!</h2>
                <div className="HomePageGoogleCompaniesImages">
                    <img src={require(`../Images/14cb1.png`)} />
                    <img src={require(`../Images/14cb2.png`)} />
                    <img src={require(`../Images/14cb3.png`)} />
                    <img src={require(`../Images/14cb4.png`)} />
                    <img src={require(`../Images/14cb5.png`)} />
                    <img src={require(`../Images/14cb6.png`)} />
                    <img src={require(`../Images/14cb7.png`)} />
                    <img src={require(`../Images/14cb8.png`)} />
                </div>
            </div>
            {/* HomePageGoogleServices */}
            <div className="HomePageGoogleServices">
                <div className="MainBanner2TopBoxMenuShadow1">
                    <h3>OUR SERVICES</h3>
                </div>
                <div className="HomePageGoogleServicesMain">
                    <div className="HomePageGoogleServicesBox">
                        <div className="HomePageGoogleServicesBoxLeft">
                            <img src={require(`../Images/12GargeNew.png`)} alt="BHO Garage Door" />
                        </div>
                        <div className="HomePageGoogleServicesBoxRight">
                            <h1>New Garage Door Installation</h1>
                            <h2>Upgrade your home’s curb appeal with our expert new garage door installation. We offer a range of styles and materials to suit your needs. Our skilled technicians ensure a seamless process, enhancing security, aesthetics, and functionality for your property.</h2>
                        </div>
                    </div>
                    <div className="HomePageGoogleServicesBox">
                        <div className="HomePageGoogleServicesBoxLeft">
                            <img src={require(`../Images/12GargeOpener.png`)} alt="BHO Garage Door" />
                        </div>
                        <div className="HomePageGoogleServicesBoxRight">
                            <h1>Garage Door Opener</h1>
                            <h2>Experience convenience with our professional garage door opener installation services. We provide reliable solutions that make accessing your garage effortless. From basic models to smart openers with remote access, we ensure a smooth, efficient setup tailored to your requirements.</h2>
                        </div>
                    </div>
                    <div className="HomePageGoogleServicesBox">
                        <div className="HomePageGoogleServicesBoxLeft">
                            <img src={require(`../Images/12GargeSpring.png`)} alt="BHO Garage Door" />
                        </div>
                        <div className="HomePageGoogleServicesBoxRight">
                            <h1>Garage Door Springs Replacement</h1>
                            <h2>Ensure safety and optimal performance with our garage door springs replacement service. Broken or worn-out springs can cause serious issues. Our team uses durable, high-quality springs to restore your door’s balance, functionality, and safety, extending its lifespan effectively.</h2>
                        </div>
                    </div>
                    <div className="HomePageGoogleServicesBox">
                        <div className="HomePageGoogleServicesBoxLeft">
                            <img src={require(`../Images/12GargeResidential.png`)} alt="BHO Garage Door" />
                        </div>
                        <div className="HomePageGoogleServicesBoxRight">
                            <h1>Residential Garage Door Repair</h1>
                            <h2>Restore your garage door’s functionality with our residential repair service. From broken cables to damaged panels, our expert technicians handle all issues. We prioritize safety, efficiency, and quality, ensuring your garage door operates smoothly and reliably after every repair.</h2>
                        </div>
                    </div>
                    <div className="HomePageGoogleServicesBox">
                        <div className="HomePageGoogleServicesBoxLeft">
                            <img src={require(`../Images/12GargeCom.png`)} alt="BHO Garage Door" />
                        </div>
                        <div className="HomePageGoogleServicesBoxRight">
                            <h1>Commercial Garage Door Services</h1>
                            <h2>Ensure seamless operations with our expert commercial garage door solutions. From installation to repair, we handle everything efficiently to minimize downtime. Our skilled team ensures durable, high-performing doors tailored to your needs. Enhance security, functionality, and reliability with our top-notch commercial garage door services.</h2>
                        </div>
                    </div>
                    <div className="HomePageGoogleServicesBox">
                        <div className="HomePageGoogleServicesBoxLeft">
                            <img src={require(`../Images/12GargeGate.png`)} alt="BHO Garage Door" />
                        </div>
                        <div className="HomePageGoogleServicesBoxRight">
                            <h1>Gate Repair and Installation Services</h1>
                            <h2>Secure and enhance your property with our professional gate repair and installation services. Whether you need a new gate or repairs to restore functionality, our skilled technicians deliver reliable, high-quality results. Trust us to provide durable, stylish solutions tailored to your security and aesthetic needs.</h2>
                        </div>
                    </div>
                </div>
                <div className="MainBanner2TopBoxMenuShadow2"></div>
            </div>
            {/* MainBanner6 */}
            <div className="MainBanner6" id="contact">
                <div className="MainBanner6Left">
                    <h2>NEED HELP? WE’RE HERE FOR YOU!</h2>
                    <h3>Reach Out to Us Anytime – We're Available 24/7 by Phone for the Fastest Response and a Complimentary Consultation.</h3>
                    <a className="MainBanner6LeftContact" href={`tel:${CitiePhone}`}>{CitiePhone}</a>
                    <div className="MainBanner4FaqMore">
                        <Faq
                            data={data}
                            styles={styles}
                            config={config}
                        />
                    </div>
                </div>
                <div className="MainBanner6Right">
                    <img src={require(`../Images/13BB5.png`)} />
                </div>
            </div>
            {/* Footer */}
            <Footer locationsMain={locationsMain} CitiesData={CitiesData} CitiePhone={CitiePhone} />
        </div >
    )
}

