import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Footer.scss';

export default function Footer({ CitiePhone, locationsMain, CitiesData }) {
    const Navigate = useNavigate()
    return (
        <div className="Footer2">
            {/* locationsMain */}
            {locationsMain.find(a => a.Phone == CitiePhone) ?
                <div className="HomePageGoogleLocation">
                    <div className="HomePageGoogleLocationLeft">
                        <h1>Our Service Area / Locations</h1>
                        <h2>Servicing <b>{CitiesData && CitiesData.City ? CitiesData.Full.toUpperCase() : "Your Area"}</b> And Surrounding Areas</h2>
                        <h3>75-Radius Arround {CitiesData && CitiesData.City ? CitiesData.City.toUpperCase() : "Your Area"}</h3>
                        {locationsMain.find(a => a.Phone == CitiePhone).Address.map((A, index) =>
                            <div key={index} className="HomePageGoogleLocationLeftBox">
                                <h4>{A.Location1}</h4>
                                <h5>{A.Address1}</h5>
                                <a href={`tel:${CitiePhone}`}>{CitiePhone}</a>
                            </div>
                        )}
                    </div>
                    <div className="HomePageGoogleLocationRight">
                        <div className="HomePageGoogleLocationRightBorder"></div>
                        <iframe src={locationsMain.find(a => a.Phone == CitiePhone).Map} ></iframe>
                    </div>
                </div>
                : null}
            <div className="HomePageGoogleFooterTop">
                <div className="HomePageGoogleFooterTopTitle">
                    <img src={require(`../../Images/music.png`)} />
                    <h1>BHO Garage Door, your home's secure tune, <br /> where safety and style play in perfect tune!</h1>
                    <img src={require(`../../Images/music.png`)} />
                </div>
            </div>
            <div className="HomePageGoogleFooterBottom">
                <div className="HomePageGoogleFooterBottomTop">
                    <img src={require(`../../Images/Logo.png`)} />
                    <div className="Header2LogoName">
                        <h1>BHO GARAGE DOOR</h1>
                        <h2>Your Local Garage Company</h2>
                    </div>
                    <p>Copyright 2001-2024 BHO Garage Door. All rights reserved. All available services, hours of operations, pricing structure, and guarantees may vary by location.</p>
                    <div className="SiteMapDiv">
                        <h1>Privacy</h1>
                        <a href="/privacy-policy">Privacy Policy</a>
                        <a href="/terms-and-conditions">Terms And Conditions</a>
                    </div>
                </div>
                {locationsMain.find(a => a.Phone == CitiePhone) ?
                    <div className="HomePageGoogleFooterMiddle">
                        {locationsMain.find(a => a.Phone == CitiePhone).Address.map((A, index) =>
                            <div className="HomePageGoogleLocationLeftBox">
                                <h4>{A.Location1}</h4>
                                <h5>{A.Address1}</h5>
                                <a href={`tel:${CitiePhone}`}>{CitiePhone}</a>
                            </div>
                        )}
                    </div>
                    : null}
                <div className="HomePageGoogleFooterBottomBottom">
                    <span>
                        <img src={require(`../../Images/quality.png`)} alt="" />
                        <a>www.bhogaragedoor.com</a>
                    </span>
                    <span>
                        <img src={require(`../../Images/quality.png`)} alt="" />
                        <a href={`tel:${CitiePhone}`}>{CitiePhone}</a>
                    </span>
                    <span>
                        <img src={require(`../../Images/quality.png`)} alt="" />
                        <a>main@bhogaragedoor.com</a>
                    </span>
                </div>
            </div>
        </div>
    )
}
