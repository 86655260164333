import React, { useState } from 'react'
import closeMenu from '../../Images/closeMenu.png'
import next from '../../Images/next.png'
import './Menu.scss';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Menu({ CitiePhone, navigateTo, setShowMenu }) {
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <div className="Menu">
            <div className="MenuBoxShadow" onClick={() => setShowMenu(false)}></div>
            <div className="MenuList">
                <div className="MenuListTop">
                    <div className="MenuMain">
                        <div className="MenuMainBox">
                            <a onClick={() => {
                                setShowMenu(false)
                                navigate(`/local${location.search}`)
                                window.scrollTo(0, 0)
                            }}>HOME PAGE</a>
                        </div>

                        <div className="MenuMainBox">
                            {location.pathname == "/local/book" ?
                                <a href={`/local${location.search}#ourservices`}>OUR SERVICES</a>
                                :
                                <a onClick={() => {
                                    setShowMenu(false)
                                    window.location.hash = 'ourservices'
                                }}>OUR SERVICES</a>
                            }
                        </div>


                        <div className="MenuMainBox">
                            {location.pathname == "/local" ?
                                <a onClick={() => {
                                    setShowMenu(false)
                                    navigate(`/local/book${location.search}`)
                                }}>SCHEDULE ONLINE</a>
                                :
                                <a onClick={() => {
                                    setShowMenu(false)
                                    navigate(`/book${location.search}`)
                                }}>SCHEDULE ONLINE</a>
                            }

                        </div>

                        <div className="MenuMainBox">
                            {location.pathname == "/local/book" ?
                                <a href={`/local${location.search}#reviews`}>CUSTOMER REVIEWS</a>
                                :
                                <a onClick={() => {
                                    setShowMenu(false)
                                    window.location.hash = 'reviews'
                                }}>CUSTOMER REVIEWS</a>
                            }
                        </div>


                        <div className="MenuMainBox">
                            {location.pathname == "/local/book" ?
                                <a href={`/local${location.search}#contact`}>CONTACT US</a>
                                :
                                <a onClick={() => {
                                    setShowMenu(false)
                                    window.location.hash = 'contact'
                                }}>CONTACT US</a>
                            }
                        </div>


                        {/* <div className="MenuMainBox">
                            <a onClick={() => {
                                setShowMenu(false)
                                window.location.hash = 'about'
                            }}>ABOUT US</a>
                        </div> */}
                    </div>
                    <div className="MenuFooter">
                        <h5>Copyright © 2024 Master Garage Door. All rights reserved.</h5>
                    </div>

                </div>
                <div className="MenuListFooter">
                    <a href={`tel:${CitiePhone}`}>CALL NOW {CitiePhone}</a>
                    {location.pathname == "/local" ?
                        <a onClick={() => {
                            setShowMenu(false)
                            navigate(`/local/book${location.search}`)
                        }}>SCHEDULE ONLINE</a>
                        :
                        <a onClick={() => {
                            setShowMenu(false)
                            navigate(`/book${location.search}`)
                        }}>SCHEDULE ONLINE</a>
                    }
                    <p>
                        Monday - Sunday
                        <br />
                        8:00 a.m. - 8:00 p.m.
                    </p>
                </div>

            </div>
        </div>
    )
}
