import React, { useState } from 'react'
import leftMenu from '../../Images/leftMenu.png'
import next from '../../Images/next.png'
import closeMenu from '../../Images/closeMenu.png'

import locationpin from '../../Images/locationpin.png'
import compliant from '../../Images/compliant.png'
import Review from '../../Images/Review.png'
import telephone from '../../Images/telephone.png'
import book from '../../Images/book.png'
import verified from '../../Images/verified.png'
import './Header.scss';
import $ from 'jquery';
import { useLocation } from "react-router-dom";

import appmoney from '../../Images/appmoney.png'
import appcalendar from '../../Images/appcalendar.png'
import appsearch from '../../Images/appsearch.png'
import appdropshipping from '../../Images/appdropshipping.png'
import appwallet from '../../Images/appwallet.png'
import Close from '../../Images/closeMenu.png'
import Arrow from '../../Images/downArrow.png'
import LogoHeader from '../../Images/LogoHeader.png'

export default function Header({ showMenu, setShowMenu, navigateTo, WebSitePages }) {
    const [OpenFirstMenu, setOpenFirstMenu] = useState(false);
    const [header, setHeader] = useState(false)
    const [header2, setHeader2] = useState(false)
    const [expleation, setExpleation] = useState(false);

    // const { pathname } = useLocation();
    // if (pathname === "/order") return null;
    return (
        <div className="Header">

            <div className="HeaderTopComputer">
                <div className="Header2" onClick={() => navigateTo("")}>
                    <img src={LogoHeader} alt="" />
                    {/* <h1><b>BHO Garage Door</b></h1> */}
                </div>

                <div className="Header1">
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                            <a href="/">Home</a>
                        </div>
                    </div>
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                            <h1>Services</h1>
                            <img src={Arrow} alt="" />
                        </div>

                        <div className="Header1BoxMenu">
                            <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Spring">Garage Door Broken Spring</a>
                            <a className="Header1BoxMenuBox" href="/service/Garage-Door-Spring-Repair">Garage Door Spring Repair</a>
                            <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Rollers">Garage Door Broken Rollers</a>
                            <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Cables">Garage Door Broken Cables</a>
                            <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Hinges">Garage Door Broken Hinges</a>
                            <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Drums">Garage Door Broken Drums</a>
                            <a className="Header1BoxMenuBox" href="/service/Garage-Door-Off-Track">Garage Door Off Track</a>
                            <a className="Header1BoxMenuBox" href="/service/Damage-Garage-Door-Section">Damage Garage Door Section</a>
                            <a className="Header1BoxMenuBox" href="/service/New-Garage-Door-Installation">New Garage Door Installation</a>
                            <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Wont-Open-Or-Close">Garage Door Opener Won't Open Or Close</a>
                            <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Remote-Is-Not-Working">Garage Door Opener Remote Is Not Working</a>
                            <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Keypad-Is-Not-Working">Garage Door Opener Keypad Is Not Working</a>
                            <a className="Header1BoxMenuBox" href="/service/Garage-Door-Maintenance">Garage Door Maintenance</a>
                            <a className="Header1BoxMenuBox" href="/service/Garage-Door-Installation">Garage Door Installation</a>
                            <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Stripped-Gear">Garage Door Opener Stripped Gear</a>
                            <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Installation">Garage Door Opener Installation</a>
                            <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Repair">Garage Door Opener Repair</a>
                            <a className="Header1BoxMenuBox" href="/service/Garage-Door-Repair">Garage Door Repair</a>
                            <a className="Header1BoxMenuBox" href="/service/Garage-Door-Services">Garage Door Services</a>
                            <a className="Header1BoxMenuBox" href="/service/Garage-Door-Near-me">Garage Door Near me</a>
                            <a className="Header1BoxMenuBox" href="/service/Garage-Door-Spring-Replacement">Garage Door Spring Replacement</a>
                            <a className="Header1BoxMenuBox" href="/service/Garage-Door-Replacement">Garage Door Replacement</a>
                            <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Chain-Repair">Garage Door Opener Chain Repair</a>
                            <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Belt-Repair">Garage Door Opener Belt Repair</a>
                            <a className="Header1BoxMenuBox" href="/service/Garage-Door-Residential">Garage Door Residential</a>
                            <a className="Header1BoxMenuBox" href="/service/Commercial-Garage-Door">Commercial Garage Door</a>
                            <a className="Header1BoxMenuBox" href="/service/Residential-Garage-Doors">Residential Garage Doors</a>
                        </div>
                    </div>
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                            <a href="/contact">Contact Us</a>
                        </div>
                    </div>
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                            <a href="/blog">Blog</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="HeaderPhone">
                <div className="Header2" onClick={() => navigateTo("")}>
                    <img src={LogoHeader} alt="" />

                </div>
                <div className='Header3'>
                    {showMenu ?
                        <img
                            style={header ? { "filter": "invert(1)", "width": "30px" } : { "filter": "invert(0)", "width": "30px" }}
                            src={closeMenu} alt=""
                            className='menuCloseBtn'
                            onClick={() => setShowMenu(false)}
                        />
                        :
                        <img
                            style={header ? { "filter": "invert(1)" } : { "filter": "invert(0)" }}
                            src={leftMenu} alt=""
                            onClick={() => setShowMenu(true)}
                        />
                    }

                </div>
            </div>

            <div className="HeaderBottom">
                <div className='Header2'>
                    <div className="Header2Box" onClick={() => navigateTo("contact")}>
                        <img src={compliant} alt="" />
                        <h2>Contact Us</h2>
                    </div>
                    <div className="Header2Box" onClick={() => navigateTo("blog")}>
                        <img src={Review} alt="" />
                        <h2 >Blog</h2>
                    </div>
                </div>
                <div className="Header1Main">
                    <div className='Header1'>
                        <a href="/book">BOOK AN APPOINTMENT<img src={book} alt="" /></a>
                    </div>
                    <div className='Header1'>
                        <a href="tel:314-350-6643">CALL 314-350-6643<img src={telephone} alt="" /></a>
                    </div>
                </div>
            </div>

            {/* Applications Toturial  */}
            {expleation ?
                <div className="ApplicationsToturial">
                    <div className="ApplicationsToturialShadow" onClick={() => setExpleation(false)}></div>
                    <div className="ApplicationsToturialMain">
                        <div className="ApplicationsToturialMainImgClose">
                            <img src={Close} alt="" onClick={() => setExpleation(false)} />
                        </div>
                        <div className="ApplicationsToturialMainHeader">
                            <h3>You Are Secure</h3>
                            <h4>We prioritize your financial security and protect you with encryption, identity verification, and the most widely secure payment processor.</h4>
                        </div>
                        <div className="ApplicationsToturialMainBody">
                            <div className="ApplicationsToturialMainBodyBox">
                                <div className="ApplicationsToturialMainBodyBoxLeft">
                                    <img src={appsearch} alt="" />
                                </div>
                                <div className="ApplicationsToturialMainBodyBoxRight">
                                    <h3>Soft Credit Check</h3>
                                    <h4>
                                        A soft inquiry allows Garage Door  to review your credit to get a sense of how much fund your business will qualified for.
                                    </h4>
                                    <h5>This will NOT affect your credit score.</h5>
                                </div>
                            </div>
                            <div className="ApplicationsToturialMainBodyBox">
                                <div className="ApplicationsToturialMainBodyBoxLeft">
                                    <img src={appmoney} alt="" />
                                </div>
                                <div className="ApplicationsToturialMainBodyBoxRight">
                                    <h3>Your Data, Encrypted</h3>
                                    <h4>We protect your personal and financial data with bank level encryption.</h4>
                                </div>
                            </div>
                            <div className="ApplicationsToturialMainBodyBox">
                                <div className="ApplicationsToturialMainBodyBoxLeft">
                                    <img src={appwallet} alt="" />
                                </div>
                                <div className="ApplicationsToturialMainBodyBoxRight">
                                    <h3>3 Minute Application</h3>
                                    <h4>Our application form is easy to fill out and can be completed in less than 3 minutes!</h4>
                                </div>
                            </div>
                            <div className="ApplicationsToturialMainBodyBox">
                                <div className="ApplicationsToturialMainBodyBoxLeft">
                                    <img src={appcalendar} alt="" />
                                </div>
                                <div className="ApplicationsToturialMainBodyBoxRight">
                                    <h3>Get Fund Today!</h3>
                                    <h4>Once you've accepted the offer, we'll promptly send you a contract that outlines the terms and conditions of the agreement.</h4>
                                </div>
                            </div>
                        </div>
                        <div className="ApplicationsToturialMainBodyClose" onClick={() => setExpleation(false)}>
                            <h1>Dismiss</h1>
                        </div>
                    </div>
                </div>
                : null}
        </div>
    )
}
